import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, GetCurrentSemester, PAGE_SIZE } from "../../constants/options";
import Layout from "../../components/shared-layout";
import VmButton from "../../components/shared-button";
import VmModal from "../../components/shared-modal";
import VmTable from "../../components/shared-table";
import Breadcrumb from "../../components/shared-breadcrumb";
import { navigate } from "gatsby";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const ExamIndexPage = observer(() => {
  const { rootStore, academicStore, examStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetExam, setTargetExam] = useState<any>();
  const [examCode, setExamCode] = useState<string>("");
  const [examDescription, setExamDescription] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [timeLimit, setTimeLimit] = useState<string>("0");
  const [displayKnowledegePt, setDisplayKnowledegePt] = useState<boolean>(true);
  const [multipleChoicePartialMark, setMultipleChoicePartialMark] = useState<boolean>(true);
  const [sameQuestionExam, setSameQuestionExam] = useState<boolean>(true);
  const [attemptAllow, setAttemptAllow] = useState<string>("1");
  const [infiniteAttempt, setInfiniteAttempt] = useState<boolean>(true);
  const [subjectId, setSubjectId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.EXAM) == 0 ||
      examStore.examList.data.length > PAGE_SIZE) {
      examStore.getExamList(PAGE_SIZE, 0);
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0);
    }
  }, []);

  const onChangeSubject = (event: SelectChangeEvent) => {
    setSubjectId(event.target.value as string);
  };

  const onChangeKnowledegePt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayKnowledegePt(event.target.checked);
  };

  const onChangeMCPartialMark = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultipleChoicePartialMark(event.target.checked);
  };

  const onChangeSameQuestionExam = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameQuestionExam(event.target.checked);
  };

  const onChangeInfiniteAttempt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfiniteAttempt(event.target.checked);
  };

  const onActionExam = () => {
    let req: any = {
      id: 0,
      examCode,
      examDescription,
      subjectId,
      startTime,
      endTime,
      displayKnowledegePoint: displayKnowledegePt,
      multipleChoicePartialMark,
      sameQuestionExam,
      timeLimit,
      attemptAllow: infiniteAttempt ? 0 : attemptAllow,
      semesterId: GetCurrentSemester().semester,
      collegeId: GetCollegeId()
    }
    if (+timeLimit < 1) {
      return;
    }
    if (new Date(startTime) > new Date(endTime)) {
      console.log(new Date(startTime) > new Date(endTime));

      return;
    }
    if (targetExam) {
      req.id = targetExam.id;
      examStore.updateExam(req).then(() => {
        rootStore.notify(t('EXAM') + t('UPDATED_B'), 'success');
        examStore.getExamList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      examStore.createExam(req).then(() => {
        rootStore.notify(t('EXAM') + t('CREATED_B'), 'success')
        examStore.getExamList(PAGE_SIZE, 0);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = examStore.examList.data.find(E => E.id == id);
      setTargetExam(target);
      setExamCode(target.examCode);
      setExamDescription(target.examDescription);
      setSubjectId(target.subjectId);
      setStartTime(target.startTime);
      setEndTime(target.endTime);
      setTimeLimit(target.timeLimit);
      setDisplayKnowledegePt(target.displayKnowledgePoint);
      setMultipleChoicePartialMark(target.multipleChoicePartialMark);
      setSameQuestionExam(target.sameQuestionExam);
      setAttemptAllow(target.attemptAllow == 0 ? "1" : target.attemptAllow);
      setInfiniteAttempt(target.attemptAllow == 0);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetExam(null);
    setExamCode("");
    setExamDescription("");
    setSubjectId("0");
    setStartTime("");
    setEndTime("");
    setTimeLimit("0");
    setAttemptAllow("1");
    setDisplayKnowledegePt(true);
    setMultipleChoicePartialMark(true);
    setSameQuestionExam(true);
    setInfiniteAttempt(true);
  }

  const handleDeleteModal = (id: number) => {
    let target = examStore.examList.data.find(s => s.id == id);
    setTargetExam(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('EXAM') + t('MODULE_B')}
      pageHeading={t('EXAM')}
      breadCrumb={[
        { label: t('EXAM') + t('MODULE_B'), to: "" },
        { label: t('OVERVIEW'), to: "/exam/overview" }
      ]}
      buttonLabel={t('ADD_A') + t('EXAM')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={examStore.loading}
        page={page}
        paginationCount={examStore.examList.totalCount == 0 ? 1 : examStore.examList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          examStore.examList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('EXAM') + t('CODE_B'), t('START_TIME'), t('END_TIME'), t('ACTION')]}
        tbody={examStore.examList.data.length > 0 &&
          examStore.examList.data.map((exam, index) => (
            <>
              <Box marginY={1} />
              <tr key={`exam_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{exam.examCode}</td>
                <td className={TD_NORMAL}>{exam.startTime.split('T')[0] + " " + exam.startTime.split('T')[1]}</td>
                <td className={TD_NORMAL}>{exam.endTime.split('T')[0] + " " + exam.endTime.split('T')[1]}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(exam.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate("/exam/exam-setting",
                      { state: { examId: exam.id } })}
                  >
                    {t('SETTING')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(exam.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetExam ? t('UPDATE_A') + t('EXAM') : t('ADD_A') + t('EXAM')}
        buttonLabel={targetExam ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetExam ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionExam}
        disabledCondition={academicStore.subjectList.length < 1 || timeLimit === "0" || timeLimit === "" ||
          subjectId == "0" || examCode === "" || examDescription === "" || startTime === "" || endTime === "" ||
          new Date(startTime) < GetCurrentSemester().startDate || new Date(startTime) > GetCurrentSemester().endDate
        }
        width={500}
      >

        <div className="grid grid-cols-2 gap-4 mt-4">
          <TextField
            value={examCode}
            label={t('EXAM') + t('CODE_B')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setExamCode(value.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>{t('SUBJECT')}</InputLabel>
            {
              academicStore.subjectList.data.length > 0 &&
              <Select
                label={t('SUBJECT')}
                sx={{ width: '100%' }}
                value={subjectId}
                onChange={onChangeSubject}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {academicStore.subjectList.data.map((sub: any, index: number) => (
                  <MenuItem key={`subject_select_${index}`} value={sub.id}>{sub.subjectCode}</MenuItem>
                ))}
              </Select>
            }
          </FormControl>
          <TextField
            value={timeLimit}
            type="number"
            label={t('TIME_LIMIT')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTimeLimit(value.target.value)}
          />
          <div className="col-span-2">
            <TextField
              value={examDescription}
              label={t('DESCRIPTION')}
              variant="outlined"
              sx={{ width: '100%' }}
              rows={2}
              multiline
              onChange={(value) => setExamDescription(value.target.value)}
            />
          </div>
          <TextField
            value={startTime}
            label={t('START_TIME')}
            type="datetime-local"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setStartTime(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={endTime}
            label={t('END_TIME')}
            type="datetime-local"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setEndTime(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            label={t('DISPLAY_KNOWLEGE_PT')}
            control={
              <Switch
                checked={displayKnowledegePt}
                onChange={onChangeKnowledegePt}
                name={t('DISPLAY_KNOWLEGE_PT')}
                color="primary"
              />}
          />
          <FormControlLabel
            label={t('ENABLE_PARTIAL_MARK_IN_MP')}
            control={
              <Switch
                checked={multipleChoicePartialMark}
                onChange={onChangeMCPartialMark}
                name={t('ENABLE_PARTIAL_MARK_IN_MP')}
                color="primary"
              />}
          />
          <FormControlLabel
            label={t('ENABLE_SAME_QUESTION_EXAM')}
            control={
              <Switch
                checked={sameQuestionExam}
                onChange={onChangeSameQuestionExam}
                name={t('ENABLE_SAME_QUESTION_EXAM')}
                color="primary"
              />}
          />
          {!infiniteAttempt && <Box />}
          <FormControlLabel
            label={t('INFINITE_ATTEMPTS_ALLOWED')}
            control={
              <Switch
                checked={infiniteAttempt}
                onChange={onChangeInfiniteAttempt}
                name={t('INFINITE_ATTEMPTS_ALLOWED')}
                color="primary"
              />}
          />
          {!infiniteAttempt && <TextField
            value={attemptAllow}
            type="number"
            label={t('ATTEMPT_ALLOW')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => {
              if (value.target.value === "0" || value.target.value.includes("-")) {
                setInfiniteAttempt(true);
                setAttemptAllow("1");
              } else setAttemptAllow(value.target.value);
            }}
          />}
        </div>
        {new Date(startTime) < new Date(GetCurrentSemester().startDate) || new Date(startTime) > new Date(GetCurrentSemester().endDate)
          && <Typography variant="caption">
            {t('INVALID_RANGE_FOR_CURRENT_SEMESTER')}Invalid date range, please refer to the date range for current semester ({GetCurrentSemester().startDate.split('T')} - {GetCurrentSemester().endDate.split('T')})
          </Typography>
        }
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => examStore.deleteExam(targetExam.id).then(() => {
          rootStore.notify(t('EXAM') + t('DELETED_B'), 'success')
          examStore.getExamList();
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_EXAM')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default ExamIndexPage;
